import { Navigate, useRoutes } from "react-router-dom-v5-compat";

import { FilterInput } from "@/features/suppliers/components/InlineFilter/types";

import { SupplierTable } from "../features/suppliers/components/MuiDataGridTable/SupplierTable";

export const AppRoutes: React.FC = () => {
    const BASE_PATH = "/suppliers";

    const onboardingFilter: FilterInput[] = [
        {
            type: "include",
            column_id: "onboarding.status",
            include_exclude_values: ["in_onboarding"],
            min_range: null,
            max_range: null,
            min_date: null,
            max_date: null,
            include_blanks: false,
        },
    ];

    const commonRoutes = [
        { path: `${BASE_PATH}/`, element: <Navigate to="overview/" replace /> },
        { path: `${BASE_PATH}/overview/*`, element: <SupplierTable baseFilter={[]} /> },
        {
            path: `${BASE_PATH}/onboarding-list/*`,
            element: <SupplierTable baseFilter={onboardingFilter} isOnboarding={true} />,
        },
    ];
    const element = useRoutes(commonRoutes);
    return <>{element}</>;
};
