import { useQuery } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { useMemo } from "react";

import { graphql } from "@/gql";
import { DataGridColumnFragment } from "@/gql/graphql";
import { useCurrencyCode } from "@/providers/CurrencyProvider";

import { ENRICHMENT_ID, NORMALIZED_INTO_ID } from "../../../constants";
import { HandleOpenUpsertContactModal } from "../../../SupplierTable";
import {
    getLastUsedTableViewId,
    getStoredSocialRiskState,
    isFromSocialRiskPage,
    StoredTableState,
} from "../../../tableUtils";

import { getActionColumn } from "./actionColumn";
import { ColumnDefinition } from "./columnDefinition";
import { getContactsColumn } from "./contactsColumn";
import { getDefaultColumn } from "./defaultColumn";
import { getDefinedColumn } from "./definedColumn";
import { getEditableCustomColumn } from "./editableCustomColumn";
import { getNonEditableColumn } from "./nonEditableColumn";
import { getRiskColumn } from "./riskColumn";

// These are columns that are on the supplier but not in the supplier table meta
// The fields are mostly bogus, as the ID is only the thing we care about
const columnsWithoutConfig = [
    {
        id: "code_of_conducts",
        name: "Code of Conducts",
        type: "TEXT",
        globalType: "TEXT",
        __typename: "SupplierTableColumn" as const,
    },
    {
        id: "certifications",
        name: "Certifications",
        type: "TEXT",
        globalType: "TEXT",
        __typename: "SupplierTableColumn" as const,
    },
] as const;

export function useDataGridColumns(
    onAddColumn: () => void,
    handleOpenUpsertContactModal: HandleOpenUpsertContactModal,
    isEditor: boolean,
    setView: (state: StoredTableState) => void
) {
    const hasCoCAndCerts = useFeatureToggle("new-upload", false);
    const {
        data: columnConfigData,
        loading,
        error,
    } = useQuery(columnsDocument, {
        onCompleted: (data) => {
            if (isFromSocialRiskPage()) {
                const socialRiskState = getStoredSocialRiskState();
                if (socialRiskState) {
                    setView(socialRiskState);
                    return;
                }
            }
            const id = getLastUsedTableViewId();
            if (id) {
                const view = data.getAllSupplierTableConfigs.supplierTableConfigs?.find((view) => view.id === id);
                if (view) {
                    setView(JSON.parse(view.state));
                }
            }
        },
    });
    const currency = useCurrencyCode() || "";

    const allColumns = useMemo(() => {
        if (hasCoCAndCerts) {
            return [...(columnConfigData?.getSupplierTableMeta?.columns ?? []), ...columnsWithoutConfig];
        } else {
            return columnConfigData?.getSupplierTableMeta?.columns ?? [];
        }
    }, [hasCoCAndCerts, columnConfigData]);

    const columnDefinitions = useMemo(() => {
        if (!columnConfigData) return [];
        const defs = toColumnDefinitions(allColumns, onAddColumn, handleOpenUpsertContactModal, isEditor, currency);
        return defs;
    }, [columnConfigData, onAddColumn, handleOpenUpsertContactModal, isEditor, currency, allColumns]);

    return {
        columns: columnDefinitions,
        loading,
        error,
        supplierTableMeta: {
            ...columnConfigData?.getSupplierTableMeta,
            columns: allColumns,
        },
        views: columnConfigData?.getAllSupplierTableConfigs?.supplierTableConfigs,
    };
}

export const columnsDocument = graphql(`
    query dataGridColumns {
        getSupplierTableMeta {
            columns {
                ...DataGridColumn
            }
        }
        getAllSupplierTableConfigs {
            supplierTableConfigs {
                id
                ...TableConfigsMenuButton_SupplierTableConfig
            }
        }
    }
`);

graphql(`
    fragment DataGridColumn on SupplierTableColumn {
        id
        name
        type
        globalType
        typeOptions {
            ... on ClassificationOptions {
                groups {
                    id
                    value
                    level
                }
            }
            ... on SelectOptions {
                choices
            }
        }
    }
`);

function isNotNull(value: ColumnDefinition | null): value is ColumnDefinition {
    return value !== null;
}

function toColumnDefinitions(
    columns: DataGridColumnFragment[],
    onAddColumn: () => void,
    handleOpenUpsertContactModal: HandleOpenUpsertContactModal,
    isEditor: boolean,
    currency: string
): ColumnDefinition[] {
    const columnDefinitions = columns
        .map((c: DataGridColumnFragment): ColumnDefinition | null => {
            return toColumnDefinition(c, currency);
        })
        .filter(isNotNull);
    const contactsColumn = getContactsColumn(handleOpenUpsertContactModal);
    const actionColumn = getActionColumn(onAddColumn, isEditor);

    return [...columnDefinitions, contactsColumn, actionColumn];
}

function toColumnDefinition(column: DataGridColumnFragment, currency: string): ColumnDefinition | null {
    if (isExcludedColumn(column)) return null;

    const defaultColumn = getDefaultColumn(column);

    let columnDefinition: Partial<ColumnDefinition> | null = null;

    columnDefinition = getDefinedColumn(column);
    if (columnDefinition !== null) return mergeColumnDefinitions(defaultColumn, columnDefinition);
    columnDefinition = getNonEditableColumn(column, currency);
    if (columnDefinition !== null) return mergeColumnDefinitions(defaultColumn, columnDefinition);
    columnDefinition = getRiskColumn(column);
    if (columnDefinition !== null) return mergeColumnDefinitions(defaultColumn, columnDefinition);
    columnDefinition = getEditableCustomColumn(column);
    if (columnDefinition !== null) return mergeColumnDefinitions(defaultColumn, columnDefinition);
    return defaultColumn;
}

function mergeColumnDefinitions(defaultColumn: ColumnDefinition, columnDefinition: Partial<ColumnDefinition>) {
    return {
        ...defaultColumn,
        ...columnDefinition,
    };
}

function isExcludedColumn(column: DataGridColumnFragment): boolean {
    const excludedColumnIds = [NORMALIZED_INTO_ID, ENRICHMENT_ID];
    return excludedColumnIds.includes(column.id);
}
