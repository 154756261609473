import { useQuery } from "@apollo/client";
import { CircleSolid, InformationCircle } from "@ignite-analytics/icons";
import { Box, Chip, Paper, Popper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { CodeOfConduct } from "@/gql/graphql";

interface CodeOfConductsFieldProps {
    codeOfConducts: CodeOfConduct[];
}

const getUsersDocument = graphql(`
    query SupplierInfo_GetUser($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                id
                fullName
            }
        }
    }
`);

export const CodeOfConductsField = ({ codeOfConducts }: CodeOfConductsFieldProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [open, setOpen] = useState(false);

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleMouseLeave = () => {
        setOpen(false);
    };

    const mostRecentCoc = [...codeOfConducts].sort(
        (a, b) => new Date(b.uploadedAt).getTime() - new Date(a.uploadedAt).getTime()
    )[0];

    const { data: usersData } = useQuery(getUsersDocument, {
        variables: { input: {} },
        onError: () => {},
    });

    const usersResult = usersData?.getUsers.result;

    return (
        <Chip
            variant="status"
            icon={<CircleSolid />}
            color={codeOfConducts.length > 0 ? "success" : "neutral"}
            label={
                <Stack direction="row" spacing={1} alignItems="center">
                    <Stack>
                        {codeOfConducts.length > 0 ? (
                            <FormattedMessage defaultMessage="Uploaded" />
                        ) : (
                            <FormattedMessage defaultMessage="Missing" />
                        )}
                    </Stack>
                    {codeOfConducts.length > 0 && (
                        <>
                            <Box
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <InformationCircle fontSize="inherit" sx={{ cursor: "pointer" }} />
                            </Box>

                            <Popper open={open} anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: 1500 }}>
                                <Paper sx={{ p: 2 }}>
                                    <Stack spacing={1}>
                                        <Stack spacing={0.5}>
                                            <Typography variant="textSm" color="textTextHelper" fontWeight={400}>
                                                <FormattedMessage defaultMessage="Filename" />
                                            </Typography>
                                            <Typography variant="textMd" fontWeight={500}>
                                                {decodeURIComponent(mostRecentCoc.originalFilename)}
                                            </Typography>
                                        </Stack>
                                        <Stack spacing={0.5}>
                                            <Typography variant="textSm" color="textTextHelper" fontWeight={400}>
                                                <FormattedMessage defaultMessage="Uploaded" />
                                            </Typography>
                                            <Typography variant="textMd" fontWeight={500}>
                                                {new Date(mostRecentCoc.uploadedAt).toLocaleDateString("en-GB", {
                                                    day: "2-digit",
                                                    month: "short",
                                                    year: "numeric",
                                                })}
                                                {mostRecentCoc.uploadedBy &&
                                                    usersResult?.find((user) => user.id === mostRecentCoc.uploadedBy)
                                                        ?.fullName &&
                                                    `, ${
                                                        usersResult.find((user) => user.id === mostRecentCoc.uploadedBy)
                                                            ?.fullName
                                                    }`}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Paper>
                            </Popper>
                        </>
                    )}
                </Stack>
            }
            size="small"
        />
    );
};
