import { Chip, Link as MuiLink, Stack, Typography } from "@mui/material";
import { GridCellParams, GridTreeNode, GridValidRowModel } from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom-v5-compat";

import { ONBOARDING_APPROVER_COLUMN_ID, ONBOARDING_COLUMN_ID } from "@/features/suppliers/components/constants";
import { DataGridColumnFragment } from "@/gql/graphql";

import { CertificationsField } from "../../../Components/CertificationsField";
import { CodeOfConductsField } from "../../../Components/CodeOfConductsField";
import { OnboardingField } from "../../../Components/OnboardingField";

import { ColumnDefinition } from "./columnDefinition";

/**
 * Defined columns are columns that are defined by us, and are not based on custom fields.
 * Includes name, reg_nr
 *
 * Returns a partial column definition intended to be merged with the default column definition
 * if the column matches a static column, otherwise null.
 */
export function getDefinedColumn(column: DataGridColumnFragment): Partial<ColumnDefinition> | null {
    switch (column.id) {
        case "name":
            return {
                width: 250,
                editable: true,
                renderCell: renderNameCell,
                hideable: false,
            };
        case "reg_nr": {
            return {
                editable: true,
            };
        }
        case "certifications":
            return {
                width: 200,
                editable: false,
                renderCell: renderCertificationsCell,
                hideable: true,
            };
        case "code_of_conducts":
            return {
                width: 200,
                editable: false,
                renderCell: renderCodeOfConductsCell,
                hideable: true,
            };
        case ONBOARDING_APPROVER_COLUMN_ID: {
            return {
                type: column.type,
                sortable: false,
                filterable: false,
                editable: false,
                renderCell(params) {
                    if (!params.row.onboardingApprover) {
                        return null;
                    }
                    return (
                        <Chip
                            label={`${params.row.onboardingApprover.lastName}, ${params.row.onboardingApprover.firstName}`}
                            size="small"
                        />
                    );
                },
                hideable: true,
                pinnable: true,
            };
        }
        case ONBOARDING_COLUMN_ID: {
            return {
                type: column.type,
                sortable: false,
                filterable: false,
                editable: false,
                renderCell(params) {
                    return (
                        <OnboardingField
                            onboardingStatus={params.row.onboardingStatus ?? undefined}
                            supplierId={params.row.id}
                        />
                    );
                },
                hideable: true,
                pinnable: true,
            };
        }
    }
    return null;
}

/**
 * Render cell for the supplier name. The open link button is hidden by default, and
 * is shown when the user hovers over the row. This CSS is controlled by custom styles
 * applied to the data grid component in order to get the on hover row effect.
 */
function renderNameCell(params: GridCellParams<GridValidRowModel, string, string, GridTreeNode>): React.ReactNode {
    const redirectUrl =
        params.row["onboardingStatus"] === "APPROVED"
            ? `/supplier-profile/${params.id}/overview`
            : `onboarding/${params.id}`;
    return (
        <Stack alignItems="left" flexWrap="nowrap" width="100%">
            <MuiLink sx={{ textUnderlineOffset: 3 }} color="inherit" component={Link} to={redirectUrl} noWrap>
                <Typography
                    variant="textSm"
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                >
                    {params.value}
                </Typography>
            </MuiLink>
        </Stack>
    );
}

function renderCertificationsCell(params: GridCellParams<GridValidRowModel>): React.ReactNode {
    const certifications = params.row.certifications;
    if (!certifications || certifications.length === 0) return null;
    return <CertificationsField certs={certifications} />;
}

/**
 * Render cell for the Code of Conducts column.
 */
function renderCodeOfConductsCell(params: GridCellParams<GridValidRowModel>): React.ReactNode {
    const codeOfConducts = params.row.codeOfConducts;
    if (!codeOfConducts || codeOfConducts.length === 0) return null;
    return <CodeOfConductsField codeOfConducts={codeOfConducts} />;
}
