import { useQuery } from "@apollo/client";
import { Stack, Chip, Popper, Paper, Typography, Divider, Box } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { Certification } from "@/gql/graphql";

import { ExpirationChip } from "../../ExpirationChip";

const getUsersDocument = graphql(`
    query SupplierInfo_GetUser($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                id
                fullName
            }
        }
    }
`);

export const CertificationsField = ({ certs }: { certs: Certification[] }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [singleOpen, setSingleOpen] = useState(false);
    const [multipleOpen, setMultipleOpen] = useState(false);

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setSingleOpen(true);
    };
    const handleMouseLeave = () => {
        setSingleOpen(false);
    };

    const handlePlusOneMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMultipleOpen(true);
    };
    const handlePlusOneMouseLeave = () => {
        setMultipleOpen(false);
    };

    const { data: usersData } = useQuery(getUsersDocument, {
        variables: { input: {} },
        onError: () => {},
    });

    if (!certs?.length) {
        return null;
    }

    const usersResult = usersData?.getUsers?.result;

    return (
        <Stack direction="row" spacing={1}>
            <Chip
                key={certs[0].documentType}
                label={getShortDocumentTypeName(certs[0].documentType)}
                color="primary"
                size="small"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            <Popper open={singleOpen} anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: 1500 }}>
                <Paper sx={{ p: 2 }}>
                    <Stack spacing={1}>
                        {certs[0].originalFilename && (
                            <>
                                <Stack spacing={0.5}>
                                    <Typography variant="textSm" color="textTextHelper" fontWeight={400}>
                                        <FormattedMessage defaultMessage="Filename" />
                                    </Typography>
                                    <Typography variant="textMd" fontWeight={500}>
                                        {decodeURIComponent(certs[0].originalFilename)}
                                    </Typography>
                                </Stack>
                                <Divider />
                            </>
                        )}
                        <Stack spacing={0.5}>
                            <Typography variant="textSm" color="textTextHelper" fontWeight={400}>
                                <FormattedMessage defaultMessage="Uploaded" />
                            </Typography>
                            <Typography variant="textMd" fontWeight={500}>
                                {new Date(certs[0].uploadedAt).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                })}
                                {certs[0].uploadedBy &&
                                    usersResult?.find((user) => user.id === certs[0].uploadedBy)?.fullName &&
                                    `, ${usersResult.find((user) => user.id === certs[0].uploadedBy)?.fullName}`}
                            </Typography>
                        </Stack>
                    </Stack>
                </Paper>
            </Popper>
            {certs.length > 1 && (
                <>
                    <Chip
                        label={`+${certs.length - 1}`}
                        color="primary"
                        size="small"
                        onMouseEnter={handlePlusOneMouseEnter}
                        onMouseLeave={handlePlusOneMouseLeave}
                    />
                    <Popper open={multipleOpen} anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: 1500 }}>
                        <Paper sx={{ p: 2 }}>
                            <Stack spacing={2}>
                                {certs.slice(1).map((certification: Certification) => (
                                    <Stack
                                        spacing={1}
                                        key={certification.filename}
                                        sx={{
                                            "&:not(:last-of-type)": {
                                                borderBottom: "1px solid",
                                                borderColor: "divider",
                                                pb: 2,
                                            },
                                        }}
                                    >
                                        <Box display="inline-block">
                                            <Chip
                                                key={certification.documentType}
                                                label={getShortDocumentTypeName(certification.documentType)}
                                                color="primary"
                                                size="small"
                                            />
                                        </Box>
                                        <Stack spacing={0.5}>
                                            <Typography variant="textSm" fontWeight={400} color="textTextHelper">
                                                <FormattedMessage defaultMessage="Uploaded" />
                                            </Typography>
                                            <Typography variant="textMd" fontWeight={500}>
                                                {new Date(certification.uploadedAt).toLocaleDateString("en-GB", {
                                                    day: "2-digit",
                                                    month: "short",
                                                    year: "numeric",
                                                })}
                                                {certification.uploadedBy &&
                                                    usersResult?.find((user) => user.id === certification.uploadedBy)
                                                        ?.fullName &&
                                                    `, ${
                                                        usersResult.find((user) => user.id === certification.uploadedBy)
                                                            ?.fullName
                                                    }`}
                                            </Typography>
                                        </Stack>
                                        {certification.originalFilename && (
                                            <Stack spacing={0.5}>
                                                <Typography variant="textSm" fontWeight={400} color="textTextHelper">
                                                    <FormattedMessage defaultMessage="File" />
                                                </Typography>
                                                <Typography variant="textMd" fontWeight={500}>
                                                    {decodeURIComponent(certification.originalFilename)}
                                                </Typography>
                                            </Stack>
                                        )}
                                        {certification.expiresAt && (
                                            <Stack spacing={0.5} display="inline-block">
                                                <ExpirationChip expiryDate={certification.expiresAt} />
                                            </Stack>
                                        )}
                                    </Stack>
                                ))}
                            </Stack>
                        </Paper>
                    </Popper>
                </>
            )}
        </Stack>
    );
};

type DocumentTypeOption = { name: string; tag: string };

const documentTypes: DocumentTypeOption[] = [
    { name: "Code of Conduct", tag: "code-of-conduct" },
    {
        name: "ISO 9001 - Quality Management Systems",
        tag: "certification-iso-9001-quality-management",
    },
    {
        name: "ISO 10002 - Customer Satisfaction Management",
        tag: "certification-iso-10002-customer-satisfaction",
    },
    {
        name: "ISO 10018 - People Engagement Management",
        tag: "certification-iso-10018-people-engagement",
    },
    {
        name: "ISO 13485 - Medical Devices Quality Management",
        tag: "certification-iso-13485-medical-devices",
    },
    {
        name: "ISO 14001 - Environmental Management Systems",
        tag: "certification-iso-14001-environmental-management",
    },
    {
        name: "ISO 14064 - Greenhouse Gas Management",
        tag: "certification-iso-14064-greenhouse-gas",
    },
    {
        name: "ISO 17025 - Laboratory Testing and Calibration",
        tag: "certification-iso-17025-laboratory-testing",
    },
    {
        name: "ISO 20000 - IT Service Management",
        tag: "certification-iso-20000-it-service-management",
    },
    {
        name: "ISO 21001 - Educational Organizations Management",
        tag: "certification-iso-21001-educational-organizations",
    },
    {
        name: "ISO 21500 - Project Management in Construction",
        tag: "certification-iso-21500-project-management",
    },
    {
        name: "ISO 21502 - Project, Program, and Portfolio Management",
        tag: "certification-iso-21502-project-program",
    },
    {
        name: "ISO 22000 - Food Safety Management",
        tag: "certification-iso-22000-food-safety",
    },
    {
        name: "ISO 22301 - Business Continuity Management",
        tag: "certification-iso-22301-business-continuity",
    },
    {
        name: "ISO 22316 - Organizational Resilience",
        tag: "certification-iso-22316-organizational-resilience",
    },
    {
        name: "ISO 26000 - Social Responsibility Guidance",
        tag: "certification-iso-26000-social-responsibility",
    },
    {
        name: "ISO 27001 - Information Security Management",
        tag: "certification-iso-27001-information-security",
    },
    {
        name: "ISO 27017 - Cloud Security Management",
        tag: "certification-iso-27017-cloud-security",
    },
    {
        name: "ISO 27701 - Privacy Information Management",
        tag: "certification-iso-27701-privacy-information",
    },
    {
        name: "ISO 28000 - Supply Chain Security Management",
        tag: "certification-iso-28000-supply-chain-security",
    },
    {
        name: "ISO 28007 - Private Maritime Security",
        tag: "certification-iso-28007-maritime-security",
    },
    {
        name: "ISO 29001 - Petroleum, Petrochemical, and Natural Gas Quality",
        tag: "certification-iso-29001-petroleum-quality",
    },
    {
        name: "ISO 29993 - Learning Services Outside Formal Education",
        tag: "certification-iso-29993-learning-services",
    },
    {
        name: "ISO 30301 - Records Management",
        tag: "certification-iso-30301-records-management",
    },
    {
        name: "ISO 31000 - Risk Management",
        tag: "certification-iso-31000-risk-management",
    },
    {
        name: "ISO 41001 - Facility Management",
        tag: "certification-iso-41001-facility-management",
    },
    {
        name: "ISO 45001 - Occupational Health and Safety",
        tag: "certification-iso-45001-occupational-health",
    },
    {
        name: "ISO 45005 - COVID-19 Safe Work Practices",
        tag: "certification-iso-45005-covid-19-safety",
    },
    {
        name: "ISO 50001 - Energy Management",
        tag: "certification-iso-50001-energy-management",
    },
    {
        name: "ISO 55001 - Asset Management",
        tag: "certification-iso-55001-asset-management",
    },
    {
        name: "ISO/IEC 17024 - Certification of Persons",
        tag: "certification-iso-iec-17024-persons",
    },
    {
        name: "ISO/TS 16949 - Automotive Production",
        tag: "certification-iso-ts-16949-automotive-production",
    },
    {
        name: "ABET (Engineering and Technology Programs) Accreditation",
        tag: "certification-abet-engineering-programs",
    },
    {
        name: "Bank Account Certificate",
        tag: "certification-bank-account-certificate",
    },
    {
        name: "Basel II/III Compliance Certification",
        tag: "certification-basel-ii-iii-compliance",
    },
    {
        name: "Debio",
        tag: "certification-debio",
    },
    {
        name: "EFQM (European Foundation for Quality Management) Excellence Model",
        tag: "certification-efqm-quality-management",
    },
    {
        name: "Fair Trade Certification",
        tag: "certification-fair-trade",
    },
    {
        name: "FSC (Forest Stewardship Council) Certification",
        tag: "certification-fsc-forest-stewardship",
    },
    {
        name: "GDPR Compliance Certification",
        tag: "certification-gdpr-compliance",
    },
    {
        name: "GRESB (Global Real Estate Sustainability Benchmark)",
        tag: "certification-gresb-real-estate",
    },
    {
        name: "IATF 16949 - Automotive Quality Management",
        tag: "certification-iatf-16949-automotive-quality",
    },
    {
        name: "Liability Insurance",
        tag: "certification-liability-insurance",
    },
    {
        name: "LEED (Leadership in Energy and Environmental Design)",
        tag: "certification-leed-environmental-design",
    },
    {
        name: "Miljøfyrtårn",
        tag: "certification-miljofyrtarn",
    },
    {
        name: "NABET (National Accreditation Board for Education and Training)",
        tag: "certification-nabet-education-training",
    },
    {
        name: "OSHA Certification (Occupational Safety and Health)",
        tag: "certification-osha-safety-health",
    },
    {
        name: "PMP (Project Management Professional)",
        tag: "certification-pmp-project-management",
    },
    {
        name: "PRINCE2 (Projects in Controlled Environments)",
        tag: "certification-prince2-projects",
    },
    {
        name: "Rainforest Alliance Certification",
        tag: "certification-rainforest-alliance",
    },
    {
        name: "Science Based Targets - (SBTi)",
        tag: "certification-science-based-targets",
    },
    {
        name: "Six Sigma Certification",
        tag: "certification-six-sigma",
    },
    {
        name: "SOC 1/2/3 (System and Organization Controls)",
        tag: "certification-soc-1-2-3-controls",
    },
    { name: "Other", tag: "other" },
];

function getShortDocumentTypeName(tag: string): string {
    const documentType = documentTypes.find((doc) => doc.tag === tag);
    if (!documentType?.name) return "";
    return documentType.name.split(" - ")[0];
}
