import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

import {
    Onboarding_GetUsersDocument,
    Onboarding_UserFragment,
    SupplierCustomFieldType,
    UseTableData_SupplierFieldsFragment,
} from "@/gql/graphql";

import { CONTACT_COLUMN_ID } from "../../../../components";
import { SupplierContacts } from "../../../../types";

import { TableData } from "./useTableData";

interface CustomFieldMap {
    [key: string]: string;
}

gql(`
    query Onboarding_GetUsers($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                id
                firstName
                lastName
                email
            }
        }
    }
`);

gql(`
    fragment Onboarding_User on RoleUser {
        id
        firstName
        lastName
    }
`);
const getRowsFromCustomFields = (
    customFields: Array<{
        __typename: "SupplierCustomField";
        id: string;
        fieldId: string;
        name: string;
        fieldType: SupplierCustomFieldType;
        dataJson: string;
    }>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> => {
    return customFields.reduce((acc: CustomFieldMap, customField) => {
        acc[customField.fieldId] = JSON.parse(customField.dataJson);
        return acc;
    }, {});
};

function toSupplierRow(
    supplier: UseTableData_SupplierFieldsFragment,
    supplierContacts: SupplierContacts,
    possibleUsers: Record<string, Onboarding_UserFragment>
): TableData {
    const approvingUser = possibleUsers[supplier.onboarding?.approverId ?? ""];
    return {
        id: supplier.id,
        name: supplier.name,
        reg_nr: supplier.orgNumber,
        country: supplier.country,
        codeOfConducts: supplier.codeOfConducts,
        certifications: supplier.certifications,
        onboardingStatus: supplier.onboarding?.status,
        onboardingApprover: approvingUser ?? undefined,
        [CONTACT_COLUMN_ID]: supplierContacts[supplier.id],
        // add all supplier custom fields as customfield.id: customfield.jsonData
        ...getRowsFromCustomFields(supplier.customFields),
    };
}

export const useRows = (
    suppliers: UseTableData_SupplierFieldsFragment[] | undefined,
    supplierContacts: SupplierContacts
): TableData[] => {
    const { data: usersData } = useQuery(Onboarding_GetUsersDocument, {
        variables: { input: { terms: "" } },
        onError: () => {
            console.error("Error fetching users");
        },
    });

    const possibleUsers = useMemo(() => {
        if (!usersData?.getUsers?.result) {
            return {};
        }
        return usersData?.getUsers?.result.reduce(
            (acc: Record<string, Onboarding_UserFragment>, user: Onboarding_UserFragment) => {
                acc[user.id] = user;
                return acc;
            },
            {}
        );
    }, [usersData]);

    return useMemo(() => {
        if (!suppliers) return [];

        return suppliers.map((supplier) => toSupplierRow(supplier, supplierContacts, possibleUsers));
    }, [suppliers, supplierContacts, possibleUsers]);
};
